const team = [
	{
		firstName: 'DOUG',
		lastName: 'JACOB',
		position: 'Co-Founder',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fdoug.jpg?alt=media&token=295d3a4b-2bfd-407d-bd68-a7515857a0ba',
			alt: 'Doug Jacob',
		},
		link: 'https://www.linkedin.com/in/doug-jacob-74b17058/',
	},
	{
		firstName: 'MICHAEL',
		lastName: 'LASTORIA',
		position: 'Co-Founder',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fmike.jpg?alt=media&token=1c91dfe0-d9a0-4cdd-aba5-29c4aff6f04a',
			alt: 'Michael Lastoria',
		},
		link: 'https://www.linkedin.com/in/michaellastoria/',
	},
	{
		firstName: 'GARRETT',
		lastName: 'SCHREIBER',
		position: 'Partner',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fgarrett.jpg?alt=media&token=d0a8c80a-2c8e-40b5-a42b-f379b8162566',
			alt: 'Garrett Schreiber',
		},
		link: 'https://www.linkedin.com/in/garrettschreiber/',
	},
	{
		firstName: 'SANDY',
		lastName: 'BEALL',
		position: 'Partner',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fsandy_v3.jpg?alt=media&token=b4eb1ff9-e5bc-474b-90fc-30454c15b4a2',
			alt: 'Sandy Beall'
		},
		link: 'https://www.linkedin.com/in/sandy-beall-873033167/',
	},
	{
		firstName: 'STEVEN',
		lastName: 'KASSIN',
		position: 'Partner',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fsteve.jpg?alt=media&token=3da1d526-b038-4a3d-8144-f005cafe0b7b',
			alt: 'Steven Kassin'
		},
		link: 'https://www.linkedin.com/in/steven-kassin/',
	},
	{
		firstName: 'EUGENE',
		lastName: 'REMM',
		position: 'Partner',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Feugene.jpg?alt=media&token=d58cabc0-b566-4453-9a53-3fe8b40b4380',
			alt: 'Eugene Remm'
		},
		link: 'https://www.linkedin.com/in/eugene-remm-77b2506b/',
	},
	{
		firstName: 'ARNAVA',
		lastName: 'ASEN',
		position: 'Partner/Strategic Director',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Farnava.jpg?alt=media&token=a807b454-8cd0-4793-8701-e92e77754c57',
			alt: 'Arnava Asen',
		},
		link: 'https://www.linkedin.com/in/arnava-asen-13960518/',
	},
	{
		firstName: 'SHEHAN',
		lastName: 'ABRAHAMS',
		position: 'Partner/Creative Director',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fshehan.jpg?alt=media&token=24c469c2-6237-49b1-8b29-0844d730e5d9',
			alt: 'Shehan Abrahams',
		},
		link: 'https://www.linkedin.com/in/shehanabrahams/',
	},
	{
		firstName: 'JED',
		lastName: 'ABBI',
		position: 'Content Creator',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fjed.jpg?alt=media&token=43eecd63-8937-4e78-8708-5565041b44cd',
			alt: 'Jed Abbi',
		},
		link: 'https://www.linkedin.com/in/jed-abbi-4bb8a6150/',
	},
	{
		firstName: 'LAUREN',
		lastName: 'GORSKY',
		position: 'Art Director',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Flauren.jpg?alt=media&token=d7c34d72-0642-4144-a5ce-e867090326cc',
			alt: 'Lauren Gorsky'
		},
		link: 'https://www.linkedin.com/in/lauren-gorsky-52398090/',
	},
	{
		firstName: 'CHELSEA',
		lastName: 'HERNANDEZ',
		position: 'Art Director',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots_01-2021%2Fchelsea.jpg?alt=media&token=468655c5-2fec-483f-b7e3-31bf9edbf8a1',
			alt: 'Chelsea Hernandez'
		},
		link: 'https://www.linkedin.com/in/chelsea-hernandez-754615143/',
	},
]

export {
	team,
}
