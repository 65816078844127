import React from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import animationData from './arrowAnimationData.json'
import {
	AnimationWrapper,
	DiagramWrapper,
	TextItem,
	TextOperator,
	TextWrapper,
	Wrapper
} from './CreativeCapitalDiagram.style'
import { ReactComponent as OperatorsSvg } from '../../../../assets/Operators.svg'

const displayName = 'CreativeCapitalDiagram'

const StyledOperatorsSvg = styled(OperatorsSvg)`
	background: black;
	z-index: 1;
    position: absolute;
    height: 2.5%;
    top: 1.04%;
`

const lottieOptions = {
	loop: false,
	autoplay: true,
	animationData: animationData,
	renderer: 'svg',
};

const Component = props => (
	<Wrapper>
		<DiagramWrapper>
			<StyledOperatorsSvg />
			<AnimationWrapper>
				<Lottie
					options={lottieOptions}
				/>
			</AnimationWrapper>
			<TextWrapper>
				<TextItem>
					Proprietary Deal Flow
				</TextItem>
				<TextOperator>+</TextOperator>
				<TextItem>
					Added Value
				</TextItem>
				<TextOperator>+</TextOperator>
				<TextItem>
					Structuring Flexibility
				</TextItem>
				<TextOperator>=</TextOperator>
				<TextItem>
					One-of-one Investment Platform
				</TextItem>
			</TextWrapper>
		</DiagramWrapper>
	</Wrapper>
)

Component.displayName = displayName
export default Component
