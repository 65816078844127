const copy = {
	introduction: 'SPAC and investment platform founded by time-tested operators and entrepreneurs'
}

const backgroundVideo = {
	src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/andves_background_01-2021.mp4?alt=media&token=34e88961-8b08-4811-951d-a8381a96251d',
	poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/andvest_background_01-2021_poster.jpg?alt=media&token=053a81ad-fc0b-4433-9016-6c3355f68a73'
}

export {
	copy,
	backgroundVideo
}
