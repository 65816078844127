import React, { useContext, useEffect, useRef, useState } from 'react'
import TeamList from './src/TeamList'
import { team } from './data'
import {
	BodyHeightDictator,
	GlobalStyles,
	ScrollOuter,
} from './Team.style'
import { GlobalContext } from 'context/GlobalContext'
import useViewUnit from 'use-view-unit'
import { spacing, breakpoint } from 'styles/variables'
import useMedia from 'use-media'

const displayName = 'TeamNew'

const Component = () => {
	const setNavigationSticky = useContext(GlobalContext).navigationSticky[1]
	const scrollRef = useRef()
	const listRef = useRef()
	const [dictatorHeight, setDictatorHeight] = useState()
	const [scrollOuterHeight, setScrollOuterHeight] = useState()
	const [scrollOuterTop, setScrollOuterTop] = useState()
	const { vh, vw } = useViewUnit()
	const isScrolling = useRef()

	const isTouchScreen = useMedia({ hover: 'none' })
	const isLargeBreakpoint = useMedia({ minWidth: breakpoint })
	const isContainerMaxBreakpoint = useMedia({ minWidth: spacing.containerMaxWidth })

	const gutterSizeLargerThanContainer = (vw() - spacing.containerMaxWidth) / 2 + spacing.base
	const computedGutterSize = isContainerMaxBreakpoint ? gutterSizeLargerThanContainer : spacing.base
	const endSpacing = computedGutterSize - (spacing.base / 2)

	const handleWindowScroll = event => {
		const scrollRefCurrent = scrollRef.current
		const horizontalScrollingBeforeStart = scrollRefCurrent.scrollLeft < 0
		const horizontalComputedScroll = scrollRefCurrent.scrollLeft + scrollRefCurrent.offsetWidth
		const horizontalScrollingAfterEnd = horizontalComputedScroll > scrollRefCurrent.scrollWidth
		if (horizontalScrollingBeforeStart || horizontalScrollingAfterEnd) {
			return
		}
		const scrollAmount = event.currentTarget.pageYOffset
		requestAnimationFrame(() => scrollRefCurrent.scrollTo(scrollAmount, 0))
	}

	const handleScrollStop = event => {
		const scrollRefCurrent = scrollRef.current
		const horizontalComputedScroll = scrollRefCurrent.scrollLeft + scrollRefCurrent.offsetWidth
		const horizontalScrollingAfterEnd = horizontalComputedScroll >= scrollRefCurrent.scrollWidth
		if (horizontalScrollingAfterEnd) {
			if (window.scrollY <= scrollRefCurrent.scrollLeft) {
				requestAnimationFrame(() => window.scrollTo(0, scrollRefCurrent.scrollLeft))
			}
			return
		}
		requestAnimationFrame(() => window.scrollTo(0, event.target.scrollLeft))
	}

	useEffect(() => {
		const scrollRefCurrent = scrollRef.current
		const handleScroll = event => {
			window.clearTimeout(isScrolling.current)
			isScrolling.current = setTimeout(() => handleScrollStop(event), 66)
		}
		if (!isTouchScreen) {
			scrollRefCurrent.addEventListener('scroll', handleScroll, { passive: true })
			window.addEventListener('scroll', handleWindowScroll, { passive: true })
		}
		return () => {
			scrollRefCurrent.removeEventListener('scroll', handleScroll)
			window.removeEventListener('scroll', handleWindowScroll)
		}
	}, [isTouchScreen])

	useEffect(() => {
		setNavigationSticky(true)
		return () => setNavigationSticky(false)
	}, [setNavigationSticky])

	useEffect(() => {
		const listWidth = listRef.current.offsetWidth
		const headerHeight = 133
		const footerHeight = 124
		const scrollOuterHeight = vh() - headerHeight - footerHeight
		const largeBreakpointDictatorHeight = listWidth - vw() + scrollOuterHeight - spacing.base + (computedGutterSize * 2) - endSpacing
		const touchDictatorHeight = vh(100) - headerHeight - footerHeight

		setScrollOuterTop(scrollOuterHeight + footerHeight)
		setScrollOuterHeight(scrollOuterHeight)

		setDictatorHeight(isTouchScreen ? touchDictatorHeight : largeBreakpointDictatorHeight)
	}, [computedGutterSize, endSpacing, isLargeBreakpoint, vh, vw, isTouchScreen])

	return (
		<>
			<GlobalStyles />
			<BodyHeightDictator
				height={dictatorHeight}
			>
				<ScrollOuter
					ref={scrollRef}
					top={scrollOuterTop}
					height={scrollOuterHeight}
					gutterSize={computedGutterSize}
				>
					<TeamList
						scrollOuterRef={scrollRef}
						team={team}
						ref={listRef}
						scrollAmount={0}
						gutterSize={computedGutterSize}
						endSpacing={endSpacing}
					/>
				</ScrollOuter>
			</BodyHeightDictator>
		</>
	)
}

Component.displayName = displayName
export default Component
