import React, { useRef, useState, useEffect, useCallback } from 'react'
import { shape, string } from 'prop-types'
import Name from './src/Name'
import Position from './src/Position'
import Video from 'components/global/Video'
import Link from 'components/global/Link'
import {
	DetailWrapper,
	GridWrapper,
	ImageWrapper,
	NameWrapper,
	PositionWrapper,
	Wrapper,
	StyledImg,
} from './TeamListItem.style'
import isInViewport from './src/utilities/isInViewport'
import useMedia from 'use-media'
import { breakpoint } from 'styles/variables'

const displayName = 'TeamListItem'

const propTypes = {
	teamMember: shape({
		firstName: string,
		lastName: string,
		link: string,
		position: string,
		video: shape({
			src: string,
			poster: string,
		}),
		image: shape({
			src: string,
			alt: string,
		}),
	}).isRequired
}

const Component = props => {
	const videoRef = useRef()
	const [ playVideo, setPlayVideo ] = useState()

	const {
		endSpacing,
		scrollOuterRef,
		teamMember: {
			firstName,
			lastName,
			link,
			position,
			video,
			image,
		}
	} = props

	const isLargeBreakpoint = useMedia({ minWidth: breakpoint })
	const isTouchScreen = useMedia({ hover: 'none' })

	const playVideoIfInViewport = useCallback(() => {
		if (isLargeBreakpoint === undefined) {
			return
		}
		if (isLargeBreakpoint && !isTouchScreen) {
			return
		}
		if (isInViewport(videoRef.current)) {
			setPlayVideo(true)
			return
		}
		setPlayVideo(false)
	}, [isLargeBreakpoint, isTouchScreen]);

	useEffect(() => {
		if (isLargeBreakpoint && !isTouchScreen) {
			setPlayVideo(false)
		}
	}, [isLargeBreakpoint, isTouchScreen])

	useEffect(() => {
		const scrollOuterRefCurrent = scrollOuterRef.current
		if(!isTouchScreen || !isLargeBreakpoint) {
			return () => scrollOuterRefCurrent.removeEventListener('scroll', playVideoIfInViewport)
		}
		scrollOuterRefCurrent.addEventListener('scroll', playVideoIfInViewport, {passive: true})
		return () => scrollOuterRefCurrent.removeEventListener('scroll', playVideoIfInViewport)
	}, [scrollOuterRef, playVideoIfInViewport, isTouchScreen, isLargeBreakpoint])

	useEffect(() => {
		window.addEventListener('scroll', playVideoIfInViewport, {passive: true})
		return () => window.removeEventListener('scroll', playVideoIfInViewport)
	}, [playVideoIfInViewport])

	useEffect(() => {
		window.addEventListener('resize', playVideoIfInViewport, { passive: true })
		return () => window.removeEventListener('resize', playVideoIfInViewport)
	}, [playVideoIfInViewport])

	const handleOnMouseEnter = () => {
		if (isLargeBreakpoint && !isTouchScreen) {
			setPlayVideo(true)
		}
	}

	const handleOnMouseLeave = () => {
		if (isLargeBreakpoint && !isTouchScreen) {
			setPlayVideo(false)
		}
	}

	useEffect(() => {
		playVideoIfInViewport()
	}, [playVideoIfInViewport])

	return (
		<Wrapper
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			endSpacing={endSpacing}
		>
			<Link
				to={link}
				external
				newTab
			>
				<GridWrapper>
					<ImageWrapper>
						{
							video && 
							<Video
								loop
								muted
								play={playVideo}
								playsInline
								poster={video.poster}
								preload="auto"
								ref={videoRef}
								resetAtPause
								src={video.src}
							/>
						}
						{
							image && (
								<StyledImg
									src={image.src}
									alt={image.alt}

								/>
							)
						}
					</ImageWrapper>
					<DetailWrapper>
						<NameWrapper>
							<Name role="text">
								{firstName}<br />{lastName}
							</Name>
						</NameWrapper>
						<PositionWrapper>
							<Position>
								{position}
							</Position>
						</PositionWrapper>
					</DetailWrapper>
				</GridWrapper>
			</Link>
		</Wrapper>
	)
}

Component.displayName = displayName
Component.propTypes = propTypes
export default Component
