import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
	color: white;

	:hover {
		opacity: 0.8;
	}
`

const featuredCopy = `
	We apply our entrepreneurial mindset and unique backgrounds to structure high-impact
	transactions that create significant value for our investors
`

const paragraphsCopy = [
	<>
		<u>SPACs</u>:  We assemble best-in-breed management teams with deep networks and access to
		off-market deals in categories where we have expertise
		<br />
		<br />
		<ul className="bullet">
			<li>
				<Link href="https://fastacq.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
					FAST: Launched August 2020 (NYSE: FST); announced merger with Fertitta Entertainment
				</Link>
			</li>
			<li>
				Velocity: Launched February 2021 (NASDAQ: VELOU)
			</li>
		</ul>
	</>,
	<>
		<u>Investments</u>:  We structure high impact investments where our hands-on involvement can
		provide added value
	</>,
	<>
		<u>Creative</u>:  Like an outsourced brand marketing team, we determine, design, and develop
		category-challenging ways of living in the consumer's mind and heart
	</>,
]

export {
	featuredCopy,
	paragraphsCopy
}
