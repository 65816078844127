import React, { useContext } from 'react'
import useMedia from 'use-media'
import NavigationLink from '../NavigationLink'
import ExternalLink from './src/ExternalLink'
import FineLink from './src/FineLink'
import Container from 'components/global/Container'
import { breakpoint } from 'styles/variables'
import {
	FooterMain,
	LinksWrapperFine,
	LinksWrapperMain,
} from './NavigationFooter.styles'
import { TwoColumnWrapper, TwoColumnRight } from 'templates/TwoColumn'
import { GlobalContext } from 'context/GlobalContext'

const displayName = 'NavigationFooter'

const Component = props => {

	const sticky = useContext(GlobalContext).navigationSticky[0]
	const largeBreakpoint = useMedia({ minWidth: breakpoint })

	return (
		<>
			<FooterMain
				sticky={sticky}
			>
				<Container
					paddingBottom
					paddingTop
				>
					<TwoColumnWrapper variant="rightOnly">
						<TwoColumnRight>
							<LinksWrapperMain>
								{
									largeBreakpoint &&
									<NavigationLink
										to="/team"
										variant="featured"
									>
										TEAM
									</NavigationLink>
								}
								<ExternalLink
									to="https://goo.gl/maps/8RLS4StDHikQSAYg7"
									newTab
								>
									3 Minetta Street,<br />
									New York, NY 10012
								</ExternalLink>
								<ExternalLink
									to="mailto:hello@andvest.co"
								>
									hello@andvest.co
								</ExternalLink>
								<ExternalLink
									to="https://www.linkedin.com/company/andvest/"
									newTab
								>
									LinkedIn
								</ExternalLink>
							</LinksWrapperMain>
						</TwoColumnRight>
					</TwoColumnWrapper>
				</Container>
			</FooterMain>
			<footer>
				<Container
					paddingBottom
				>
					<LinksWrapperFine>
						<FineLink to="/disclaimer">
							Disclaimer
						</FineLink>
					</LinksWrapperFine>
				</Container>
			</footer>
		</>
	)
}

Component.displayName = displayName
export default Component
